import * as Sentry from '@sentry/nextjs';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import cn from '@/components/blocks/FeedbackForm/FeedbackFormContent/style.module.sass';
import CheckboxWrapper from '@/components/blocks/forms/UI/CheckboxWrapper';
import InputWrapper from '@/components/blocks/forms/UI/InputWrapper';
import RadioInputWrapper from '@/components/blocks/forms/UI/RadioInputWrapper';
import SelectWrapper from '@/components/blocks/forms/UI/SelectWrapper';
import TextAreaWrapper from '@/components/blocks/forms/UI/TextAreaWrapper';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import { FileUpload } from '@/components/UI/FileUpload';
import { ceoConfig, IOptionsItem, optionsMortgage } from '@/data/blocks/feedbackFormData';
import { IBXResponseForms } from '@/types/requests';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

interface IFormFields {
    currentTheme: IOptionsItem;
    setModalText: (arg: string) => void;
    templateName: string;
    descFlag: boolean;
    acceptFlag: boolean;
    options: IOptionsItem[];
    buttonText: string;
    isSubmitDisabled: boolean;
    setIsModalOpen: (arg: boolean) => void;
}

type IFinalData = {
    accept: string;
    address: string;
    email: string;
    message: string;
    fio: string;
    phone: string;
    theme: string;
    product?: string;
};

const config = {
    textarea: TextAreaWrapper,
    input: InputWrapper,
    select: SelectWrapper,
    file: FileUpload,
    radio: RadioInputWrapper,
    checkbox: CheckboxWrapper,
};

const FormFields = ({
    currentTheme,
    setModalText,
    templateName,
    descFlag = true,
    acceptFlag = true,
    buttonText,
    options,
    isSubmitDisabled,
    setIsModalOpen,
}: IFormFields) => {
    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        resetField,
        register,
        setValue,
        watch,
        reset,
    } = useFormContext();

    const recaptchaRef = useRef<ReCAPTCHA>();

    const [isErrorAccept, errorAcceptText] = getFormErrors(errors, 'accept');
    const [url, setUrl] = useState('');

    const isCheckedDisabledConditions =
        currentTheme.value === 'Выберите тему обращения' || currentTheme.value === 'Выберите тему';

    const pathname = usePathname();
    const product = pathname.split('/')[pathname.split('/').length - 1];

    useEffect(() => {
        if (typeof window !== undefined) {
            setUrl(window.location.href);
        }
    }, []);

    useEffect(() => {
        register('file');
    }, [register]);

    useEffect(() => {
        const fields: string[] = Object.keys(errors);

        if (fields.length > 0) {
            fields.forEach(item => {
                const nameField = ceoConfig[item] || item;

                universalEvent({
                    eventName: 'pbFormEvent',
                    category: 'Interactions',
                    action: 'field_unsuccess',
                    label: `${product}_form_${nameField}`,
                });
            });
        }
    }, [errors]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const acceptChecked = useWatch({ name: 'accept', control });

    const onSubmit = async (finalData: IFinalData) => {
        const token = await recaptchaRef.current.executeAsync();

        const filteredFinalData = removePropertyFromObject(
            { ...finalData, theme: currentTheme.value, token },
            'accept'
        );
        const formData = getFormDataFromHookForm({
            ...filteredFinalData,
            formTemplate: templateName,
        });

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${templateName}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setModalText('Произошла ошибка при отправке формы.');

                Sentry.captureMessage(
                    `Произошла ошибка при отправке формы ${templateName} -- ${response?.data?.errors[0].message}`,
                    'error'
                );
            } else {
                universalEvent({
                    eventName: 'pbFormEvent',
                    category: 'AppSent',
                    action: `spprt:${product}`,
                    label: url,
                });

                const fields: string[] = Object.keys({ theme: currentTheme.value, ...finalData });
                fields.forEach((item, index) => {
                    const nameField = ceoConfig[item] || item;

                    universalEvent({
                        eventName: 'pbFormEvent',
                        category: 'Interactions',
                        action: 'field_success',
                        label: `${product}_form_${nameField}`,
                    });
                });
            }
        } catch (e) {
            console.error(e);
        }
        reset();
        setIsModalOpen(true);
        resetField('accept', { defaultValue: false });
    };

    return (
        <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            {currentTheme?.fields.map((item, index) => {
                const { type, name, disabled, label, fileTypes, dependent, defaultValue, dependentOn, dependentValue } =
                    item;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const Component = config[type];

                if (type === 'select' || type === 'file') {
                    return (
                        <Component
                            name={name}
                            item={item}
                            key={`${name}-${index}`}
                            errors={errors}
                            {...register(name)}
                            control={control}
                            setValue={setValue}
                            disabled={disabled || !acceptChecked}
                            onFileChange={(files: File[]) => setValue('file', files, { shouldValidate: true })}
                            label={label}
                            fileTypes={fileTypes}
                            isError={getFormErrors(errors, name)[0]}
                            errorText={getFormErrors(errors, name)[1]}
                        />
                    );
                }

                if (dependent) {
                    return (
                        watch(`${dependentOn}`) === dependentValue && (
                            <Controller
                                key={`${name}-${index}`}
                                render={({ field }) => (
                                    <Component
                                        {...field}
                                        item={item}
                                        errors={errors}
                                        setValue={setValue}
                                        disabled={disabled}
                                    />
                                )}
                                name={name}
                                control={control}
                                defaultValue=""
                            />
                        )
                    );
                }

                return (
                    <Controller
                        key={`${name}-${index}`}
                        render={({ field }) => (
                            <Component
                                {...field}
                                item={item}
                                errors={errors}
                                setValue={setValue}
                                disabled={disabled}
                                name={name}
                            />
                        )}
                        name={name}
                        control={control}
                        defaultValue={defaultValue || ''}
                    />
                );
            })}

            {descFlag && <div className={cn.desc}>Срок ответа банка составит 5 рабочих дней</div>}

            {acceptFlag && (
                <div className={cn.input}>
                    <Checkbox
                        {...register('accept')}
                        size="small"
                        isDisabled={isCheckedDisabledConditions}
                        isError={isErrorAccept}
                        errorText={errorAcceptText}
                        label='Даю согласие на <a href="/personal-data">обработку персональных данных</a>'
                    />
                </div>
            )}

            <Button
                variant="btnBlue"
                buttonType="submit"
                type="button"
                label={options === optionsMortgage ? 'Подать заявку' : buttonText}
                disable={isSubmitDisabled || isSubmitting}
                customClass={cn.button}
                animation={false}
            />
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.PUBLIC_RECAPTHCA_KEY || 'test_key'} />
        </form>
    );
};

export default FormFields;
