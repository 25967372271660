import { schemaConfig } from '@/hooks/useValidationSchema';
import { ISelectElement } from '@/types/select';

export interface IOptionsItem {
    value: string;
    label: string;
    fields?: IFieldsItem[];
}

export interface IFieldsItem {
    dependent?: boolean;
    dependentOn?: string;
    dependentValue?: string;
    type?: 'input' | 'textarea' | 'select' | 'file' | 'radio' | 'checkbox' | 'textBlock';
    inputType?: string;
    fileTypes?: string[];
    label?: string;
    value?: string;
    placeholder?: string;
    mask?: string;
    name?: string;
    list?: ISelectElement<string>[];
    disabled?: boolean;
    innerList?: IFieldsItem[];
    validate?: keyof typeof schemaConfig;
    active?: boolean;
    defaultValue?: unknown;
    customClass?: string;
    required?: boolean;
}

export const ceoConfig: { [key: string]: string } = {
    nameOrganization: 'company_name',
    theme: 'subject_selected',
    message: 'text',
};

export const optionsAppeal: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                disabled: true,
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Не поступают СМС-сообщения от Банка',
        label: 'Не поступают СМС-сообщения от Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Ошибка в Почта Банк Онлайн',
        label: 'Ошибка в Почта Банк Онлайн',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Дата и время возникновения ошибки',
                name: 'dateError',
                validate: 'required',
                required: false,
            },
            {
                type: 'file',
                label: 'Скриншот / фото экрана с ошибкой',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Название и версия ОС (для Мобильного приложения): Android / iOS /Windows Mobile',
                name: 'os',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'URL страницы с ошибкой (для Интернет-банка): my.pochtabank.ru/online.pochtabank.ru',
                name: 'url',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Описание ошибки',
                name: 'message',
                validate: 'required',
                required: false,
            },
        ],
    },
    {
        value: 'Отзыв персональных данных',
        label: 'Отзыв персональных данных',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Причина отзыва персональных данных',
                name: 'revokingData',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Номер телефона, который требуется исключить',
                mask: '+7 (999) 999-99-99',
                name: 'phoneExclude',
                validate: 'phone',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Отказ от маркетинговых коммуникаций',
        label: 'Отказ от маркетинговых коммуникаций',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'select',
                placeholder: 'Вид маркетинговых коммуникаций',
                name: 'typeMarketingComm',
                list: [
                    { label: 'Звонки', value: 'Звонки' },
                    { label: 'СМС-сообщения', value: 'СМС-сообщения' },
                    { label: 'Звонки и СМС-сообщения', value: 'Звонки и СМС-сообщения' },
                ],
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Номер телефона, который требуется исключить',
                mask: '+7 (999) 999-99-99',
                name: 'phoneExclude',
                validate: 'phone',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Страховой случай',
        label: 'Страховой случай',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Дата страхового случая',
                name: 'dateEvent',
                validate: 'required',
                required: false,
            },
            {
                type: 'select',
                name: 'event',
                placeholder: 'Страховое событие',
                list: [
                    { label: 'Инвалидность', value: 'Инвалидность' },
                    { label: 'Травма', value: 'Травма' },
                    { label: 'Госпитализация', value: 'Госпитализация' },
                    { label: 'Смерть', value: 'Смерть' },
                ],
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Группа инвалидности',
                name: 'disabilityGroup',
                placeholder: 'При выборе страхового события Инвалидность',
                dependent: true,
                dependentOn: 'event',
                dependentValue: 'Инвалидность',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Вопросы по услугам для ЮЛ и ИП',
        label: 'Вопросы по услугам для ЮЛ и ИП',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Благодарность',
        label: 'Благодарность',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Запросы по банкротству клиентов Банка',
        label: 'Запросы по банкротству клиентов Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsPretensionSecond: IOptionsItem[] = [
    {
        value: 'Выберите тему',
        label: 'Выберите тему',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                disabled: true,
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.bmp', '.tif', '.tiff'],
                defaultValue: [],
                disabled: true,
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Зарегистрировать жалобу/претензию',
        label: 'Зарегистрировать жалобу/претензию',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.bmp', '.tif', '.tiff'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        label: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.bmp', '.tif', '.tiff'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Направить дополнительные сведения по зарегистрированной жалобе',
        label: 'Направить дополнительные сведения по зарегистрированной жалобе',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.bmp', '.tif', '.tiff'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Оставить благодарность ',
        label: 'Оставить благодарность ',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.bmp', '.tif', '.tiff'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
];

export const optionsPretension: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                disabled: true,
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Зарегистрировать жалобу/претензию',
        label: 'Зарегистрировать жалобу/претензию',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Узнать статус и/или срок рассмотрения зарегистрированной жалобы/претензии',
        label: 'Узнать статус и/или срок рассмотрения зарегистрированной жалобы/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        label: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Связаться с Отделом, в котором рассматривается зарегистрированная жалоба/претензия',
        label: 'Связаться с Отделом, в котором рассматривается зарегистрированная жалоба/претензия',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Получить письменный ответ по рассмотренной жалобе/претензии',
        label: 'Получить письменный ответ по рассмотренной жалобе/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsMortgage: IOptionsItem[] = [
    {
        value: 'Форма обратной связи по ипотеке',
        label: 'Форма обратной связи по ипотеке',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Рабочий e-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'select',
                placeholder: 'Какой продукт интересует',
                name: 'product',
                list: [
                    {
                        label: 'Строительство дома на собственном земельном участке',
                        value: 'Строительство дома на собственном земельном участке',
                    },
                    {
                        label: 'Строительство дома и покупка земельного участка',
                        value: 'Строительство дома и покупка земельного участка',
                    },
                ],
                validate: 'required',
                required: false,
            },
        ],
    },
];

export const optionsOffRequests: IOptionsItem[] = [
    {
        value: 'Официальные запросы (МВД, нотариус и др.)',
        label: 'Официальные запросы (МВД, нотариус и др.)',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsHotLine: IOptionsItem[] = [
    {
        value: 'Горячая линия по комплаенс',
        label: 'Горячая линия по комплаенс',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsBank: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                disabled: true,
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Стать партнером Банка по программе «Покупки в кредит»',
        label: 'Стать партнером Банка по программе «Покупки в кредит»',
        fields: [
            {
                type: 'select',
                inputType: 'city',
                placeholder: 'Выберите город из списка',
                name: 'city',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН',
                name: 'inn',
                validate: 'inn',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Сотрудничество с банком по рекламе',
        label: 'Сотрудничество с банком по рекламе',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Вид рекламы: ТВ, радио, наружная реклама и т.д.',
                name: 'advertisingType',
                placeholder: 'ТВ, радио, наружная реклама и т.д.',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Сотрудничество с банком (прочее)',
        label: 'Сотрудничество с банком (прочее)',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес организации',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'select',
                inputType: 'region',
                placeholder: 'Выберите регион из списка',
                label: 'Регион',
                name: 'region',
                validate: 'required',
                required: false,
            },
            {
                type: 'select',
                placeholder: 'Выберите город из списка',
                inputType: 'city',
                name: 'city',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Название организации',
                name: 'nameOrganization',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Вид деятельности',
                name: 'kindActivity',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsQuestions: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                disabled: true,
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Предложение разместить банкомат Почта Банк',
        label: 'Предложение разместить банкомат Почта Банк',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Адрес размещения банкомата',
                name: 'addressAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Неработоспособность банкомата Почта Банка',
        label: 'Неработоспособность банкомата Почта Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Номер банкомата',
                name: 'numberAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Адрес размещения банкомата',
                name: 'addressAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const options: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                disabled: true,
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Не поступают СМС-сообщения от Банка',
        label: 'Не поступают СМС-сообщения от Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Ошибка в Почта Банк Онлайн',
        label: 'Ошибка в Почта Банк Онлайн',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Дата и время возникновения ошибки',
                name: 'dateError',
                validate: 'required',
                required: false,
            },
            {
                type: 'file',
                label: 'Скриншот / фото экрана с ошибкой',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Название и версия ОС (для Мобильного приложения): Android / iOS /Windows Mobile',
                name: 'os',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'URL страницы с ошибкой (для Интернет-банка): my.pochtabank.ru/online.pochtabank.ru',
                name: 'url',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Описание ошибки',
                name: 'message',
                validate: 'required',
                required: false,
            },
        ],
    },
    {
        value: 'Отзыв персональных данных',
        label: 'Отзыв персональных данных',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Причина отзыва персональных данных',
                name: 'revokingData',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Номер телефона, который требуется исключить',
                mask: '+7 (999) 999-99-99',
                name: 'phoneExclude',
                validate: 'phone',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Отказ от маркетинговых коммуникаций',
        label: 'Отказ от маркетинговых коммуникаций',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'select',
                placeholder: 'Вид маркетинговых коммуникаций',
                name: 'typeMarketingComm',
                list: [
                    { label: 'Звонки', value: 'Звонки' },
                    { label: 'СМС-сообщения', value: 'СМС-сообщения' },
                    { label: 'Звонки и СМС-сообщения', value: 'Звонки и СМС-сообщения' },
                ],
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Номер телефона, который требуется исключить',
                mask: '+7 (999) 999-99-99',
                name: 'phoneExclude',
                validate: 'phone',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Неработоспособность банкомата Почта Банка',
        label: 'Неработоспособность банкомата Почта Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Номер банкомата',
                name: 'numberAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Адрес размещения банкомата',
                name: 'addressAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Предложение разместить банкомат Почта Банк',
        label: 'Предложение разместить банкомат Почта Банк',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Адрес размещения банкомата',
                name: 'addressAtm',
                validate: 'required',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Страховой случай',
        label: 'Страховой случай',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Дата страхового случая',
                name: 'dateEvent',
                validate: 'required',
                required: false,
            },
            {
                type: 'select',
                name: 'event',
                placeholder: 'Страховое событие',
                list: [
                    { label: 'Инвалидность', value: 'Инвалидность' },
                    { label: 'Травма', value: 'Травма' },
                    { label: 'Госпитализация', value: 'Госпитализация' },
                    { label: 'Смерть', value: 'Смерть' },
                ],
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Группа инвалидности',
                name: 'disabilityGroup',
                placeholder: 'При выборе страхового события Инвалидность',
                dependent: true,
                dependentOn: 'event',
                dependentValue: 'Инвалидность',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Стать партнером Банка по программе «Покупки в кредит»',
        label: 'Стать партнером Банка по программе «Покупки в кредит»',
        fields: [
            {
                type: 'select',
                inputType: 'city',
                placeholder: 'Выберите город из списка',
                name: 'city',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН',
                name: 'inn',
                validate: 'inn',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Сотрудничество с банком по рекламе',
        label: 'Сотрудничество с банком по рекламе',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Вид рекламы: ТВ, радио, наружная реклама и т.д.',
                name: 'advertisingType',
                placeholder: 'ТВ, радио, наружная реклама и т.д.',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Сотрудничество с банком (прочее)',
        label: 'Сотрудничество с банком (прочее)',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес организации',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'select',
                inputType: 'region',
                placeholder: 'Выберите регион из списка',
                label: 'Регион',
                name: 'region',
                validate: 'required',
                required: false,
            },
            {
                type: 'select',
                placeholder: 'Выберите город из списка',
                inputType: 'city',
                name: 'city',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Название организации',
                name: 'nameOrganization',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Вид деятельности',
                name: 'kindActivity',
                validate: 'required',
                required: false,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Официальные запросы (МВД, нотариус и др.)',
        label: 'Официальные запросы (МВД, нотариус и др.)',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Вопросы по услугам для ЮЛ и ИП',
        label: 'Вопросы по услугам для ЮЛ и ИП',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Зарегистрировать жалобу/претензию',
        label: 'Зарегистрировать жалобу/претензию',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Благодарность',
        label: 'Благодарность',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Узнать статус и/или срок рассмотрения зарегистрированной жалобы/претензии',
        label: 'Узнать статус и/или срок рассмотрения зарегистрированной жалобы/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        label: 'Получить разъяснения по решению, принятому по жалобе/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Связаться с Отделом, в котором рассматривается зарегистрированная жалоба/претензия',
        label: 'Связаться с Отделом, в котором рассматривается зарегистрированная жалоба/претензия',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Получить письменный ответ по рассмотренной жалобе/претензии',
        label: 'Получить письменный ответ по рассмотренной жалобе/претензии',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Запросы по банкротству клиентов Банка',
        label: 'Запросы по банкротству клиентов Банка',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Горячая линия по комплаенс',
        label: 'Горячая линия по комплаенс',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'Фактический адрес',
                name: 'address',
                validate: 'required',
                required: false,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
];

export const optionsV2: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                disabled: true,
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                disabled: true,
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Ошибки при получении токена',
        label: 'Ошибки при получении токена',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Реализация метода валидации сервиса на стороне юридических лиц',
        label: 'Реализация метода валидации сервиса на стороне юридических лиц',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Ошибки на этапе реализации',
        label: 'Ошибки на этапе реализации',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Ошибки при проведении оплаты с использованием сервиса',
        label: 'Ошибки при проведении оплаты с использованием сервиса',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
    {
        value: 'Прочие вопросы',
        label: 'Прочие вопросы',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'Наименование организации',
                name: 'organization',
                validate: 'organization',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН Организации',
                name: 'inn',
                validate: 'required',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },

            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
            {
                type: 'file',
                label: 'Прикрепить файл',
                name: 'file',
                fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
                defaultValue: [],
                validate: 'arrayOfStringsOptional',
                required: false,
            },
        ],
    },
];

export const optionsV3: IOptionsItem[] = [
    {
        value: 'Выберите тему обращения',
        label: 'Выберите тему обращения',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                disabled: true,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                disabled: true,
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                disabled: true,
                required: true,
            },
        ],
    },
    {
        value: 'Консультация по Вашему договору и счетам',
        label: 'Консультация по Вашему договору и счетам',
        fields: [
            {
                type: 'checkbox',
                label: 'Адрес моей электронной почты подтвержден',
                name: 'email_confirmed',
                validate: 'checkbox',
                defaultValue: false,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Консультация без проверки информации по договору',
        label: 'Консультация без проверки информации по договору',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Арест счета',
        label: 'Арест счета',
        fields: [
            {
                type: 'checkbox',
                label: 'Адрес моей электронной почты подтвержден',
                name: 'email_confirmed',
                validate: 'checkbox',
                defaultValue: false,
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'E-mail',
                name: 'email',
                validate: 'email',
                required: true,
            },
            {
                type: 'textarea',
                label: 'Текст сообщения',
                name: 'message',
                validate: 'required',
                required: true,
            },
        ],
    },
    {
        value: 'Отсутствует нужная тематика',
        label: 'Отсутствует нужная тематика',
        fields: [
            {
                type: 'textBlock',
                label: 'Найти ответ на свой вопрос <a href="/support/faq">здесь</a>',
            },
            {
                type: 'textBlock',
                label: 'Задать свой вопрос в  <a href="/feedback">ЧАТ</a>',
            },
        ],
    },
];
