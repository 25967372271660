import { v4 } from 'uuid';

interface IData {
    [name: string]: unknown;
    file?: File[];
}

export const getFormDataFromHookForm = (data: IData, fileFields: string[] = ['file']) => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        if (fileFields.includes(key)) {
            (data[key] as File[])?.forEach(file => formData.append(`${key}[]`, file, v4() + file.name));
            return;
        }

        formData.append(key, (data[key] as string) || '');
    });

    return formData;
};
