import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent } from 'react';

import AnimatedComponent from '@/components/Animation';
import { useTheme } from '@/context/ThemeProvider';

import cn from './style.module.sass';

export interface IRadio {
    value?: string;
    label: string;
    onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => unknown;
    isError?: boolean;
    errorText?: string;
    customClass?: string;
    checked?: boolean;
    size?: 'default' | 'small';
    isDisabled?: boolean;
    name: string;
}

const Radio = observer<IRadio, HTMLInputElement>(
    (
        { value, checked, label, onChangeHandler, isError, errorText, customClass, isDisabled, size, name, ...rest },
        ref
    ) => {
        const { theme } = useTheme();

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                    className={clsx(cn.wrapper, theme === 'dark' && cn.dark, size === 'small' && cn.small, customClass)}
                >
                    {/* eslint-disable */}
                    <input
                        disabled={isDisabled}
                        onChange={onChangeHandler}
                        value={value}
                        className={clsx(cn.input, !isError && cn.valid)}
                        type="radio"
                        // checked={checked}
                        defaultChecked={checked}
                        name={name}
                        required
                        ref={ref}
                        {...rest}
                    />
                    <span className={cn.checkmark} />
                    {label && <span className={cn.label} dangerouslySetInnerHTML={{ __html: label }} />}
                </label>
                {isError && (
                    <AnimatedComponent key={errorText} className={cn.error} classNameActive={cn.errorAnimate}>
                        {errorText}
                    </AnimatedComponent>
                )}
            </>
        );
    },
    { forwardRef: true }
);

export default Radio;
