import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';

import FormFields from '@/components/blocks/FeedbackForm/FeedbackFormContent/FormFields';
import SelectTheme from '@/components/blocks/FeedbackForm/FeedbackFormContent/SelectTheme';
import SubscriptionModal from '@/components/SubscriptionModal';
import TextField from '@/components/TextField';
import { FieldConfig, InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IFeedbackFormContent } from '@/types/feedbackForm/item';

import cn from './style.module.sass';

const FeedbackFormContent: React.FC<IFeedbackFormContent> = ({
    title,
    description,
    successTitle,
    templateName,
    themeDescList,
    options,
    descFlag,
    thanksSubTitle,
    showLabel = true,
    acceptFlag = true,
    buttonText = 'Отправить',
}) => {
    const isSubmitDisabledConditions =
        options[0].value === 'Выберите тему обращения' || options[0].value === 'Выберите тему';

    const [currentTheme, setCurrentTheme] = useState(options[0]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(isSubmitDisabledConditions);

    const [isGratitude, setIsGratitude] = useState<boolean>(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState(successTitle);

    const schema = useMemo(() => {
        if (!currentTheme) return [];

        return currentTheme?.fields?.reduce(
            (acc, item) => {
                if (item.validate && item.name) {
                    acc.push({
                        name: item.name,
                        type: item.validate,
                        required: item.required,
                    });
                }

                return acc;
            },
            [
                {
                    name: 'accept',
                    type: 'accept',
                    required: true,
                },
                { name: 'theme', type: 'arrayOfStringsOptional', required: false },
            ] as FieldConfig[]
        );
    }, [currentTheme]);

    const resolver = useValidationSchema(schema);

    const methods = useForm<InnerFormValues>({
        resolver,
        mode: 'onChange',
        defaultValues: { accept: false },
    });

    const {
        reset,
        control,
        formState: { isSubmitSuccessful },
    } = methods;

    useEffect(() => {
        if (currentTheme.label.includes('благодарность')) {
            setIsGratitude(true);
        } else {
            setIsGratitude(false);
        }
        const defaultValues: DefaultValues<InnerFormValues> = currentTheme?.fields?.reduce((acc, item) => {
            acc[item.name] = item?.defaultValue ?? '';

            return acc;
        }, {});
        reset(defaultValues);
    }, [currentTheme]);

    return (
        <FormProvider {...methods}>
            <div className={clsx(cn.wrapper, 'section')}>
                <div className={cn.fullWrapper}>
                    <div className={cn.innerWrapper}>
                        <div className={cn.wrapperForm}>
                            <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                            {description && (
                                <TextField
                                    text={description}
                                    name="description"
                                    isHTML
                                    htmlValue={description}
                                    className={cn.description}
                                />
                            )}

                            <SelectTheme
                                {...control}
                                options={options}
                                setCurrentTheme={setCurrentTheme}
                                setIsSubmitDisabled={setIsSubmitDisabled}
                                currentTheme={currentTheme}
                                showLabel={showLabel}
                                themeDescList={themeDescList}
                            />

                            {currentTheme?.fields.find(item => item.type === 'textBlock') ? (
                                <div className={cn.form}>
                                    {currentTheme?.fields.map((item, index) => (
                                        <div
                                            key={`${item.name}-${index}`}
                                            className={cn.input}
                                            dangerouslySetInnerHTML={{ __html: item?.label }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <FormFields
                                    currentTheme={currentTheme}
                                    setModalText={setModalText}
                                    templateName={templateName}
                                    descFlag={descFlag}
                                    acceptFlag={acceptFlag}
                                    options={options}
                                    buttonText={buttonText}
                                    isSubmitDisabled={isSubmitDisabled}
                                    setIsModalOpen={setIsModalOpen}
                                />
                            )}
                        </div>
                    </div>
                    <SubscriptionModal
                        isFail={!isSubmitSuccessful}
                        isOpen={isModalOpen}
                        handleClose={() => setIsModalOpen(false)}
                        title={modalText}
                        subTitle={isGratitude ? thanksSubTitle : null}
                    />
                </div>
            </div>
        </FormProvider>
    );
};

export default FeedbackFormContent;
