import React from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

import ControlledCitySelect from '@/components/blocks/forms/UI/ControlledCitySelect';
import CustomSelect from '@/components/UI/CustomSelect';
import { IFieldsItem } from '@/data/blocks/feedbackFormData';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { ISelectElement } from '@/types/select';
import { getFormErrors } from '@/utils/forms/getFormErrors';

import cn from './style.module.sass';

interface IProps {
    item: IFieldsItem;
    errors: FieldErrors<InnerFormValues>;
    control?: Control;
    isDefaultValueStringNeeded?: boolean;
    [name: string]: unknown;
}

const SelectWrapper: React.FC<IProps> = ({ item, errors, control, isDefaultValueStringNeeded = false }) => {
    if (item.inputType === 'city' || item.inputType === 'region') {
        return (
            <div className={cn.input}>
                <ControlledCitySelect
                    control={control}
                    shouldColored
                    name={item.name}
                    type={item.inputType}
                    placeholder={item?.placeholder || 'Выберите из списка'}
                    isError={getFormErrors(errors, item.name)[0]}
                    errorText={getFormErrors(errors, item.name)[1]}
                />
            </div>
        );
    }

    return (
        <div className={cn.input}>
            <label htmlFor={item.name}>{item.label}</label>
            <Controller
                control={control}
                defaultValue={isDefaultValueStringNeeded ? item.list[0]?.value : item.list[0]}
                name={item.name}
                render={({ field }) => (
                    <CustomSelect
                        {...field}
                        value={item.list.find(elem => elem.value === field.value)}
                        onChange={(e: ISelectElement<string>) => {
                            field.onChange(e.value);
                        }}
                        options={item.list}
                        placeholder={item.placeholder}
                        isError={getFormErrors(errors, item.name)[0]}
                        errorText={getFormErrors(errors, item.name)[1]}
                    />
                )}
            />
        </div>
    );
};

export default SelectWrapper;
