import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent } from 'react';

import AnimatedComponent from '@/components/Animation';
import { useTheme } from '@/context/ThemeProvider';

import cn from './style.module.sass';

export interface ICheckbox {
    value?: string;
    label: string;
    onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => unknown;
    isError?: boolean;
    errorText?: string;
    customClass?: string;
    size?: 'default' | 'small';
    isDisabled?: boolean;
    [name: string]: unknown;
    classCheckmark?: string;
    subtext?: string;
}

const Checkbox = observer<ICheckbox, HTMLInputElement>(
    (
        {
            value,
            label,
            subtext,
            onChangeHandler,
            isError,
            errorText,
            customClass,
            isDisabled,
            size,
            classCheckmark,
            ...rest
        },
        ref
    ) => {
        const { theme } = useTheme();

        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                    className={clsx(cn.wrapper, theme === 'dark' && cn.dark, size === 'small' && cn.small, customClass)}
                >
                    {/* eslint-disable */}
                    <input
                        disabled={isDisabled}
                        onChange={onChangeHandler}
                        value={value}
                        className={clsx(cn.input, !isError && cn.valid)}
                        type="checkbox"
                        required
                        ref={ref}
                        {...rest}
                    />
                    <span className={clsx(cn.checkmark, classCheckmark)} />
                    {label && (
                        <span
                            className={clsx(cn.label, subtext && cn.blockLabel)}
                            dangerouslySetInnerHTML={{ __html: label }}
                        />
                    )}
                    {subtext && <div className={cn.subtext} dangerouslySetInnerHTML={{ __html: subtext }} />}
                </label>
                {isError && (
                    <AnimatedComponent key={errorText} className={cn.error} classNameActive={cn.errorAnimate}>
                        {errorText}
                    </AnimatedComponent>
                )}
            </>
        );
    },
    { forwardRef: true }
);

export default Checkbox;
