import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';

import AnimatedComponent from '@/components/Animation';
import Radio from '@/components/UI/RadioBtn';
import { IFieldsItem } from '@/data/blocks/feedbackFormData';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { getFormErrors } from '@/utils/forms/getFormErrors';

import cn from './style.module.sass';

interface IProps {
    item: IFieldsItem;
    errors?: FieldErrors<InnerFormValues>;
    isError?: boolean;
    errorText?: string;
    customClass?: string;
    [name: string]: unknown;
}

const RadioInputWrapper: React.FC<IProps> = forwardRef<HTMLInputElement, IProps>(
    ({ item, errors, isError, errorText, customClass, ...rest }, ref) => (
        <div className={clsx(cn.wrapper, customClass)}>
            {item?.label && (
                <label htmlFor={item.name} className={cn.labelOuter} dangerouslySetInnerHTML={{ __html: item.label }} />
            )}

            <div className={cn.row}>
                {item?.innerList.map((elem, index) => (
                    <div className={cn.input} key={index}>
                        <Radio
                            name={elem.name}
                            value={elem.value}
                            label={elem.label}
                            checked={elem.active}
                            isError={getFormErrors(errors, item.name)[0]}
                            errorText={getFormErrors(errors, item.name)[1]}
                            customClass={clsx(customClass, elem?.customClass)}
                            ref={ref}
                            {...rest}
                        />
                    </div>
                ))}
            </div>

            {isError && (
                <AnimatedComponent key={errorText} className={cn.error} classNameActive={cn.errorAnimate}>
                    {errorText}
                </AnimatedComponent>
            )}
        </div>
    )
);

export default RadioInputWrapper;
