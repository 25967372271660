import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import cn from '@/components/blocks/FeedbackForm/FeedbackFormContent/style.module.sass';
import CustomSelect from '@/components/UI/CustomSelect';
import { IOptionsItem } from '@/data/blocks/feedbackFormData';
import { ISelectElement } from '@/types/select';

interface ISelectTheme {
    options: IOptionsItem[];
    setCurrentTheme: (theme: IOptionsItem) => void;
    setIsSubmitDisabled: (isDisabled: boolean) => void;
    showLabel: boolean;
    currentTheme: IOptionsItem;
    themeDescList: { desc: string }[];
}

function SelectTheme({
    options,
    showLabel,
    setCurrentTheme,
    currentTheme,
    setIsSubmitDisabled,
    themeDescList,
}: ISelectTheme) {
    const [currentThemeIndex, setCurrentThemeIndex] = useState(-1);
    const { clearErrors, setValue } = useFormContext();
    const { register } = useFormContext();

    if (options?.length > 1) {
        return (
            <>
                <div className={cn.input}>
                    {showLabel && <label htmlFor="theme">Выберите тему обращения</label>}

                    <CustomSelect
                        {...register('theme')}
                        value={options.find(item => item.value === currentTheme.value)}
                        onChange={(e: ISelectElement<string>) => {
                            clearErrors();
                            setCurrentTheme(options.find(item => item.value === e.value));
                            const index = options.findIndex(item => item.value === e.value);
                            setCurrentThemeIndex(index <= 0 ? -1 : index - 1);
                            setIsSubmitDisabled(e.value === options[0].value);
                            setValue('accept', false);
                        }}
                        options={options}
                        placeholder="Выберите тему"
                    />
                </div>
                {themeDescList && currentThemeIndex >= 0 && themeDescList[currentThemeIndex]?.desc && (
                    <div
                        className={cn.desc}
                        dangerouslySetInnerHTML={{ __html: themeDescList[currentThemeIndex]?.desc }}
                    />
                )}
            </>
        );
    }
    return null;
}

export default SelectTheme;
