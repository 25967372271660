import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';

import Checkbox from '@/components/UI/Checkbox';
import { IFieldsItem } from '@/data/blocks/feedbackFormData';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { getFormErrors } from '@/utils/forms/getFormErrors';

import cn from './style.module.sass';

interface IProps {
    item: IFieldsItem;
    errors: FieldErrors<InnerFormValues>;
    [name: string]: unknown;
}

const CheckboxWrapper: React.FC<IProps> = forwardRef(
    ({ item, errors, ...rest }, ref: ForwardedRef<HTMLInputElement>) => (
        <div className={clsx(cn.input)}>
            <Checkbox
                name={item?.name}
                size="small"
                isError={getFormErrors(errors, item.name)[0]}
                errorText={getFormErrors(errors, item.name)[1]}
                label={item.label}
                ref={ref}
                {...rest}
            />
        </div>
    )
);

export default CheckboxWrapper;
