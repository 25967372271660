import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';

import CustomSelect from '@/components/UI/CustomSelect';
import { ICity } from '@/types/city/city';
import { IBXResponse } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { apiRequest } from '@/utils/request';

interface IProps {
    control: Control;
    setValue?: UseFormSetValue<FieldValues>;
    name?: string;
    type?: string;
    placeholder?: string;
    [name: string]: unknown;
    isPrivateBank?: boolean;
    customClass?: string;
    defaultValue?: ISelectElement<string>;
}

const ControlledCitySelect: React.FC<IProps> = ({
    control,
    name = 'city',
    type = 'city',
    placeholder = 'Выберите отделение Банка',
    customClass,
    isPrivateBank = false,
    defaultValue,
    setValue,
    ...rest
}) => {
    const [selectValue, setSelectValue] = useState<ISelectElement<string>>(null);
    const [selectInputValue, setSelectInputValue] = useState<string>(null);
    const [selectOptions, setSelectOptions] = useState<ISelectElement<string>[]>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>(null);

    const onInputChange = (value: string) => {
        setSelectInputValue(value);
        clearTimeout(timeout.current);

        if (!value) {
            setSelectOptions([]);
            setIsOptionsLoading(false);
            return;
        }

        setIsOptionsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        timeout.current = setTimeout(async () => {
            const response: IBXResponse<ICity[]> = await apiRequest(
                `/city/${type === 'region' ? 'regionlist' : 'list'}?q=${value}`
            );
            const citiesList = response.data.data;
            const newOptions = citiesList.map(city => ({ label: city.city, value: city.city }));

            setSelectOptions(newOptions);
            setIsOptionsLoading(false);
        }, 1000);
    };

    const getNoOptionsMessage = () => {
        if (selectInputValue) return 'По вашему запросу ничего не найдено';
        return `Введите название ${type === 'region' ? 'региона' : 'города'}`;
    };

    useEffect(() => {
        if (defaultValue) {
            setSelectValue(defaultValue);
            setValue(name, defaultValue.value);
        }
    }, [defaultValue]);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <CustomSelect
                    {...field}
                    value={selectValue}
                    onInputChange={onInputChange}
                    onChange={(e: ISelectElement<string>) => {
                        setSelectValue(selectOptions.find(elem => elem.value === e.value));
                        field.onChange(e.value);
                    }}
                    options={selectOptions}
                    noOptionsMessage={getNoOptionsMessage}
                    placeholder={placeholder}
                    isLoading={isOptionsLoading}
                    loadingMessage={() => `Загружаем список ${type === 'region' ? 'регионов' : 'городов'}`}
                    customClass={customClass}
                    isPrivateBank={isPrivateBank}
                    {...rest}
                />
            )}
        />
    );
};

export default ControlledCitySelect;
