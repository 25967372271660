import clsx from 'clsx';
import React, { forwardRef } from 'react';

import cn from './style.module.sass';

interface IProps {
    value?: string;
    isError?: boolean;
    placeholder?: string;
    errorText?: string;
    className?: string;
    [name: string]: unknown;
}

const TextArea: React.FC<IProps> = forwardRef<HTMLTextAreaElement, IProps>(
    ({ value, className, placeholder, isError, errorText, ...rest }, ref) => (
        <div className={clsx(cn.wrapper, isError && cn.wrapperError, className)}>
            <textarea value={value} placeholder={placeholder} className={cn.textarea} ref={ref} {...rest} />
            {isError && (
                <div key={errorText} className={cn.error}>
                    {errorText}
                </div>
            )}
        </div>
    )
);

export default TextArea;
