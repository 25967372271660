import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

import CustomImage from '@/components/CustomImage';

import { FileIcon } from './icon';
import cn from './style.module.sass';

interface IProps {
    [name: string]: unknown;
    onFileChange?: (files: File[]) => void;
    label?: string;
    fileTypes?: string | string[];
    type?: 'primary' | 'secondary';
    isError?: boolean;
    errorText?: string;
    disabled?: boolean;
}

export const FileUpload: React.FC<IProps> = ({
    onFileChange,
    label,
    fileTypes,
    type = 'primary',
    isError,
    errorText,
    disabled,
}) => {
    const options = {
        noClick: true,
        noKeyboard: true,
        accept: undefined,
        maxSize: undefined,
    };

    if (fileTypes) {
        options.accept = fileTypes;
        options.maxSize = 3145728;
    }

    const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone(options);

    const [typeError, setTypeError] = useState<boolean>(false);
    const [sizeError, setSizeError] = useState<boolean>(false);

    const [localAcceptedFiles, setLocalAcceptedFiles] = useState(acceptedFiles);
    const [localFileRejections, setLocalFileRejections] = useState(fileRejections);
    const {
        formState: { isSubmitting },
    } = useFormContext();
    useEffect(() => {
        const modifiedAcceptedFiles = acceptedFiles?.map(file =>
            Object.defineProperties(file, {
                name: {
                    value: v4() + file.name,
                },
            })
        );

        onFileChange(modifiedAcceptedFiles);

        setLocalAcceptedFiles(modifiedAcceptedFiles);
    }, [acceptedFiles]);

    useEffect(() => {
        fileRejections?.forEach(item =>
            item.errors.forEach(innerItem => {
                if (innerItem.code === 'file-invalid-type') {
                    setTypeError(true);
                } else {
                    setTypeError(false);
                }

                if (innerItem.code === 'file-too-large') {
                    setSizeError(true);
                } else {
                    setSizeError(false);
                }
            })
        );

        setLocalFileRejections(fileRejections);
    }, [fileRejections]);

    useEffect(() => {
        if (isSubmitting) {
            onFileChange([]);

            setLocalAcceptedFiles([]);
            setLocalFileRejections([]);
        }
    }, [isSubmitting]);

    return (
        <div className={cn.wrap}>
            <div {...getRootProps()} className={cn.dropzone}>
                <input {...getInputProps()} />
                <button
                    type="button"
                    onClick={open}
                    disabled={disabled}
                    className={clsx(cn.dropzoneBtn, {
                        [cn.dropzoneBtn_primary]: type === 'primary',
                        [cn.dropzoneBtn_secondary]: type === 'secondary',
                    })}
                >
                    {type === 'primary' && (
                        <div className={clsx(disabled && cn.disabled, cn.attachmentWrapper)}>
                            <CustomImage src="/img-next/svg/attachment.svg" alt={label} width={26} height={26} />
                        </div>
                    )}
                    {type === 'secondary' && (
                        <CustomImage src="/img-next/svg/attach.svg" alt={label} width={16} height={16} />
                    )}
                    <span className={cn.dropzoneBtnText}>{label}</span>
                </button>
            </div>
            {localAcceptedFiles?.length > 0 && (
                <div className={cn.filesList}>
                    {localAcceptedFiles?.map(file => (
                        <div key={file.name} className={cn.filesItem}>
                            <FileIcon />
                            <span className={cn.filesName}>{file.name}</span>
                        </div>
                    ))}
                </div>
            )}

            {localFileRejections && localFileRejections?.length > 0 && (
                <>
                    {typeError && (
                        <div className={cn.filesListErrors}>
                            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                            {`Допустимые типы файлов: ${fileTypes}`}
                        </div>
                    )}

                    {sizeError && <div className={cn.filesListErrors}>Максимальный размер файла 3МБ</div>}
                </>
            )}

            {isError && <div className={cn.filesListErrors}>{errorText}</div>}
        </div>
    );
};
