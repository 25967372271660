import React, { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';

import TextArea from '@/components/UI/TextArea';
import { IFieldsItem } from '@/data/blocks/feedbackFormData';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { getFormErrors } from '@/utils/forms/getFormErrors';

import cn from './style.module.sass';

interface IProps {
    item: IFieldsItem;
    errors: FieldErrors<InnerFormValues>;
    [name: string]: unknown;
}

const TextAreaWrapper: React.FC<IProps> = forwardRef(({ item, errors, ...rest }, ref) => (
    <div className={cn.input}>
        <TextArea
            placeholder={item.label}
            isError={getFormErrors(errors, item.name)[0]}
            errorText={getFormErrors(errors, item.name)[1]}
            ref={ref}
            {...rest}
        />
    </div>
));

export default TextAreaWrapper;
