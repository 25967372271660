import clsx from 'clsx';
import React from 'react';
import { FieldErrors } from 'react-hook-form';

import Input from '@/components/UI/Input';
import { IFieldsItem } from '@/data/blocks/feedbackFormData';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { getFormErrors } from '@/utils/forms/getFormErrors';

import cn from './style.module.sass';

interface IProps {
    item: IFieldsItem;
    errors: FieldErrors<InnerFormValues>;
    [name: string]: unknown;
}

const InputWrapper: React.FC<IProps> = ({ item, errors, ...rest }) => (
    <div className={clsx(cn.input, item.mask && cn.inputMask)}>
        <Input
            customClass={cn.isFull}
            shouldColored
            type={item.type}
            label={item.label}
            mask={item.mask}
            isError={getFormErrors(errors, item.name)[0]}
            errorText={getFormErrors(errors, item.name)[1]}
            {...rest}
        />
    </div>
);

export default InputWrapper;
